import Lottie from "lottie-react";
import ico from "./lottie/ico.json";
import lock from "./lottie/lock.json";
import { FadeIn } from "./animations/Fade";
import { Logos } from "./animations/logos";
import CookieConsent from "react-cookie-consent";
function About() {
  FadeIn();
  Logos();

  return (
    <div className="about">
      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <div className="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div className="row align-items-stretch ">
              <div className="col-lg-12 section-title fade">
                <h6>Who we are</h6>
              </div>
            </div>
            <h2>We are the experts in B2B Cyber Security Marketing</h2>
            <h4>
              Cyber security is an industry that requires deep subject
              knowledge, and the ability to convey that to the layperson. We
              know the industry like the backs of our hands.
            </h4>
          </div>
          <div className="col-lg-6">
            <Lottie speed={0.25} animationData={ico} autoPlay={false} />
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row align-items-stretch">
          <div class="col-lg-2 stripe"></div>
          <div class="col-lg-8">
            <div class="row align-items-center">
              <div class="col-lg-12 left margin-bottom">
                <div class="list__item">
                  <div class="row">
                    <div class="col-lg-4">
                      <h2>okta</h2>
                    </div>
                    <div class="col-lg-2">
                      <p>SEO</p>
                      <p>Content</p>
                      <p>Stategy</p>
                    </div>
                    <div class="col-lg-6">
                      <h4>
                        Develop, deploy, measure and manage the international
                        marketing optimisation strategies that help Okta connect
                        and nurture meaningful relationships with new customers
                        in diverse global markets.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 left margin-bottom">
                <div class="list__item">
                  <div class="row">
                    <div class="col-lg-4">
                      <h2>IDEMIA</h2>
                    </div>
                    <div class="col-lg-2">
                      <p>Web</p>
                      <p>Content marketing</p>
                      <p>Social</p>
                    </div>
                    <div class="col-lg-6">
                      <h4>
                        Create strategies and brands that communicate IDEMIA’s
                        world-class experience in biometrics, cryptography, data
                        analytics, systems and smart devices to governments and
                        enterprise.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 left margin-bottom">
                <div class="list__item">
                  <div class="row">
                    <div class="col-lg-4">
                      <h2>trust-hub</h2>
                    </div>
                    <div class="col-lg-2">
                      <p>Web</p>
                      <p>Content marketing</p>
                      <p>Social</p>
                    </div>
                    <div class="col-lg-6">
                      <h4>
                        Develop and launch an agile SAAS brand equipped to
                        communicate the challenges and opportunities data
                        privacy initiatives like GDPR or CCPA create for large
                        enterprises operating globally today.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row align-items-stretch">
          <div class="col-lg-6 fade">
            <div className="row align-items-stretch ">
              <div className="col-lg-12 section-title fade">
                <h6>How we do it</h6>
              </div>
            </div>
            <h2>Become an NOW|insider</h2>
            <h4>
              As an agile marketing agency, launching integrated campaigns that
              are fuelled by data is in our blood. We bring content marketing,
              PPC campaigns, lead nurturing, web design and social media
              management together to build your brand and generate sales-ready
              leads.
            </h4>
            <p>
              Become part of our marketing team with hacks, check-ins, training
              and analytics reporting, helping you to link marketing efforts
              directly to sales increases.{" "}
            </p>
            <div class="trigger-stagger-up">
              <div class="bg-dark stand-out stagger-up">
                <h4>Each client and each project is unique.</h4>
                <p>
                  That’s why our Agile Marketing Stack is built flexibly with
                  each client, empowering them to tailor efforts to their
                  priorities. Take a look at our different services to see
                  what’s inspiring you today:
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 left">
            <Lottie speed={0.25} animationData={lock} autoPlay={false} />
          </div>
        </div>
      </div>
      <div className="contact bg-dark padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 call-to">
              <h2>Ready to launch?</h2>
            </div>
            <div className="col-lg-4">
              <div className="button-container">
                <a className="btn-form" href="/offer">
                  {" "}
                  View offer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
