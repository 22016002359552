import Lottie from "lottie-react";
import offer from "./lottie/offer.json";
import { FadeIn } from "./animations/Fade";
import CookieConsent from "react-cookie-consent";
import React from "react";
import { Helmet } from "react-helmet";
function Offer(props) {
  FadeIn();
  const date = new Date();
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    date
  );
  const year = new Date().getFullYear();

  const {
    siteTitle = "Offer | Free Cyber Security Marketing Session | NowComms",
    metaDescription = "Experience the power of agile cyber security marketing in a free, two-hour, custom built PowerHack - get more details here.",
  } = props;
  return (
    <div className="contact bg-dark">
      <Helmet>
        <title>{siteTitle}</title>
        <meta
          name="keywords"
          content="marketing,agency,b2b,security,desing,seo,content"
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={metaDescription}
        />
        <meta property="og:locale" key="og:locale" content="en_GB" />
        <meta name="description" content={metaDescription} />
        <meta property="og:title" key="og:title" content={siteTitle} />
        <meta
          property="og:image"
          key="og:image"
          content="https://nowcomms.com/wp-content/uploads/2021/11/thing.png"
        />
      </Helmet>
      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <section class="padding">
        <div class="container">
          <div class="row align-items-center ">
            <div class="col-lg-6">
              <h4 class="cyber-text">
                {month} {year} <span class="green">Free</span> offer
              </h4>
              <h1>
                Experience the power of agile marketing in{" "}
                <span class="green">two hours </span>with a custom built
                PowerHack
              </h1>
              <p>
                We’re offering agile-curious marketers in the cyber security
                space a free two-hour PowerHack this winter. To claim yours,
                just tell us one big marketing challenge you want to resolve in
                the next <span class="green">90 days</span>.
              </p>
            </div>
            <div class="col-lg-6">
              <Lottie speed={0.25} animationData={offer} autoPlay={false} />
            </div>
          </div>
        </div>
      </section>
      <div class="container">
        <section class="padding left">
          <div class="row align-items-center trigger-stagger-up">
            <div class="col-lg-6">
              <h2>What you’ll get</h2>
              <h4>
                Your personal agile marketing PowerHack delivers a three month
                agile roadmap.
              </h4>
              <ul>
                <li>Use the roadmap’s horizon goals to stay on track.</li>
                <li>
                  Ensure tactical actions are always aligned to strategic goals
                  with agile customer, user or staff stories.{" "}
                </li>
                <li>
                  Learn how to adapt and respond to change with our agile
                  marketing playbook.
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <img
                class="full"
                src="https://nowcomms.com/wp-content/uploads/2022/10/roadmap-no-shadow.png"
                alt="roadmap"
              />
            </div>
            <div className="col-lg-12 stagger-up">
              <div className="button-container text-center">
                <h4>Let’s do it! Book your free Hack now</h4>
                <a className="btn-form" href="/form">
                  {" "}
                  Start NOW
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="padding left">
          <div class="row trigger-stagger-up">
            <div class="col-lg-12">
              <h2>But what is a PowerHack?</h2>
              <p>
                In the volatile, ever-changing world of cyber security, nobody
                wants to make the wrong marketing decisions. But how can you
                know what the right move is? Taking a leaf from the Agile
                Marketing Playbook allows companies to make safe decisions
                faster. Small, focused changes to your marketing enables quick
                learning at lower costs and leads to steady marginal gains.
              </p>

              <p>
                As a B2B marketing agency, we use Hack sessions to decide with
                partners what their agile marketing strategy is going to look
                like for the next three months. That is, what incremental
                changes are we going to test and - if successful - scale in
                order to fix their current marketing weak spots?
              </p>
              <p>
                In this quarterly confab between our cross-functional team and
                your stakeholders we set out a marketing roadmap that plots
                small adaptive, iterative campaigns along a journey of discovery
                for the brand: nailing down what works, and scaling up.
              </p>
            </div>
            <div className="col-lg-12 stagger-up">
              <div className="button-container text-center">
                <h4>Sounds interesting! Book your free Hack now</h4>
                <a className="btn-form" href="/form">
                  {" "}
                  Start NOW
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="padding left">
          <div class="row trigger-stagger-up">
            <div class="col-lg-12">
              <h2>And what do you get out of it?</h2>
              <h4>
                Our challenge to you:{" "}
                <span class="green">
                  do you have what it takes to be agile?
                </span>
              </h4>
              <p>
                We know that agile marketing isn’t for everyone, but we also
                believe that many haven’t given agile its fair shot. We’re
                offering cyber security businesses who get in touch before 31st
                March* a free two-hour PowerHack between their best people and
                ours.
              </p>

              <p>
                In those two hours we’ll work together to plan out your agile
                marketing roadmap. The roadmap will set out your business’
                horizon goals for the next three months and customer, user and
                staff stories. These will be your lodestars when it comes to
                deciding tactical actions.
              </p>

              <p>
                <span class="green">
                  After the Hack you’ll receive your marketing roadmap, what
                  we’d recommend you do next, a couple of resources to get
                  started and (small sales pitch incoming!) some info on how we
                  can help.
                </span>
              </p>
            </div>
            <div className="col-lg-12 stagger-up">
              <div className="button-container text-center">
                <h4>Sounds pretty great right? Book your free Hack now</h4>
                <a className="btn-form" href="/form">
                  {" "}
                  Start NOW
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="padding left">
          <div class="row">
            <div class="col-lg-12">
              <h2>Example hack outputs</h2>
              <h4 class="green">Scenario 1:</h4>
              <p>
                {" "}
                A SaaS security company created a placeholder website when they
                first started up, and haven’t had the time to update it with
                their latest product information since. In the fast-changing
                world of cyber security, an outdated website can be fatal for an
                organisation.
              </p>
              <h4 class="green">Recommendations:</h4>
              <p>
                {" "}
                An agile web design strategy is the quickest way to get your
                website up-to-date whilst still baking in the flexibility for
                future changes. Create a proper online home for your brand: we
                can design, build and, if desired, even host your new site
                within four to eight weeks.
              </p>

              <h4 class="green">Scenario 2:</h4>
              <p>
                A cloud solutions provider knows its personas down to a T: every
                new service is perfectly suited for their target audience and
                their content gets shared constantly online. However, this isn’t
                translating into a consistent flow of sales.
              </p>

              <h4 class="green">Recommendations:</h4>
              <p>
                Looks like the gap between their sales and marketing teams needs
                bridging. The next step is going to be to evaluate how the two
                teams work together - what gaps can be plugged in the business’
                processes to make sure everybody’s set up for success? From
                there we’d take a look at, and then refine, their current lead
                nurture journey to make sure leads are being passed to sales
                only when they’re truly ready to convert.
              </p>
              <h4 class="green">Scenario 3:</h4>
              <p>
                An IT strategy consultancy company has a bank of brilliant
                content sitting on their blog page, but nobody’s reading it:
                prospects are all coming to them from their PPC and social media
                ads instead.{" "}
              </p>
              <h4 class="green">Recommendations:</h4>
              <p>
                Blog pages are great gateways to your brand from organic
                traffic. Let’s encourage them to get there with a social media
                strategy - getting your content in front of interested followers
                - and an SEO audit - revising your content to answer the
                questions your prospects are actually asking.
              </p>
            </div>
          </div>
        </section>
        <section class="padding trigger-stagger-up">
          <div class="row align-items-center stagger-up">
            <div className="col-lg-7">
              <h2>
                Want to know how you can level up your marketing activity?{" "}
              </h2>
              <h4>Sign up for your free Hack session now:</h4>
            </div>
            <div class="col-lg-5">
              <a className="btn-form" href="/form">
                {" "}
                Start NOW
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Offer;
