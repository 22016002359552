import React from "react";
import { Outlet } from "react-router-dom";

function Blog() {
  return (
    <div className="home">
      <div class="container"></div>
      <Outlet />
    </div>
  );
}

export default Blog;
