import React from "react";

function Why() {
  return (
    <div className="home primary">
      <div class="container">
        <div class="row align-items-center primary row-hero">
          <div class="col-lg-4">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src=""
              alt=""
            />
          </div>
          <div class="col-lg-8">
            <h1 class="font-weight-light hero"><span>why</span> NOW</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-lg-3">
              <h3>this is figure one</h3>
              <p> Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.</p>
          </div>
          <div class="col-lg-3">
              <h3>this is figure one</h3>
              <p> Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.</p>
          </div>
          <div class="col-lg-3">
              <h3>this is figure one</h3>
              <p> Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.</p>
          </div>
          <div class="col-lg-3">
              <h3>this is figure one</h3>
              <p> Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Why;