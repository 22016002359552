import{useLayoutEffect} from 'react'

import $ from 'jquery';
import gsap from "gsap";
function Logos() {
    useLayoutEffect(() => {
    initMarquee(130, 40);
  }, []);

  function initMarquee(boxWidth, time) {
    const boxElement = $('.logo-box');
    const boxLength = boxElement.length;
    const wrapperWidth = boxWidth * boxLength;
    const windowWidth = $(window).width();

    boxElement.parent().css('left', '-' + boxWidth + 'px');
    boxElement.css('width', boxWidth + 'px');

    gsap.set(".logo-box", {
        x: (i) => i * boxWidth + windowWidth
    });

    gsap.to(".logo-box", {
        duration: time,
        ease: "none",
        x: "-=" + wrapperWidth,
        modifiers: {
            x: gsap.utils.unitize(
                function(x) {
                    return parseFloat(x + windowWidth + boxWidth) % wrapperWidth
                }
            )
        },
        repeat: -1
    });
  }

}

export {Logos};