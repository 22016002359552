import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

function Navigation() {
  <CookieConsent>
    This website uses cookies to enhance the user experience.
  </CookieConsent>;

  const location = useLocation();
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="no-flex">
                <NavLink className="navbar-brand" to="/">
                  NOW|Secure
                </NavLink>
              </div>
              <div className="tagline">
                {location.pathname === "/"
                  ? "AGILE CYBER SECURITY MARKETING"
                  : "AGILE CYBER SECURITY MARKETING"}
              </div>
            </div>
            <div className="col-lg-6 stripe"></div>
          </div>
          <div class="nav-flex">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink className="nav-link" to="/offer">
                  Offer
                </NavLink>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/blog">
                  Blog
                </a>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/form">
                  Contact
                </NavLink>
              </li>
            </ul>
            <ul class="navbar-nav ml-auto">
              <li className="nav-item">
                <a
                  target="_blank"
                  className="nav-link"
                  href="https://www.nowcomms.com/"
                >
                  About Now|Comms
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;
