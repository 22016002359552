import {useLayoutEffect} from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";



  function FadeIn() {
    useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

 
    
    const left = Array.from(document.getElementsByClassName("left"));
    
    left.forEach((item, index) => {
      gsap.set(item, { opacity: 0, x: index % 1 ? 100 : -100 });
    
      gsap.to(item, {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: item,
          start: "top 70%",
          end: "top",
          // toggleActions: "${onEnter} ${onLeave} ${onEnterBack} ${onLeaveBack}"
          // options: play, pause, resume, reset, restart, complete, reverse, and none
          toggleActions: "play none none reverse"
          // once: true,
          // markers: true
        }
      });
    });


    const right = Array.from(document.getElementsByClassName("right"));
    
    right.forEach((item, index) => {
      gsap.set(item, { opacity: 0, x: index % 1 ? -100 : 100 });
    
      gsap.to(item, {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: item,
          start: "top 70%",
          end: "top",
          // toggleActions: "${onEnter} ${onLeave} ${onEnterBack} ${onLeaveBack}"
          // options: play, pause, resume, reset, restart, complete, reverse, and none
          toggleActions: "play none none reverse"
          // once: true,
          // markers: true
        }
      });
    });


    const fade = Array.from(document.getElementsByClassName("fade"));

    fade.forEach((item, index) => {
      gsap.set(item, { opacity: 0 });
    
      gsap.to(item, {
        opacity: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: item,
          start: "top 70%",
          end: "top",
          toggleActions: "play none none reverse",
          scrub: true,
          delay: index*0.4,
        }
      });
    
      gsap.to(item.getElementsByClassName("faster"), {
        opacity: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: item,
          start: "top 90%",
          end: "top",
          toggleActions: "play none none reverse",
          scrub: true,
          delay: index*0.8 + 0.1, // stagger the animation by 0.1 seconds
        }
      });
    });





    



    const rows = gsap.utils.toArray('.trigger-stagger-up'); 

    const tl = [];
    
    rows.forEach((row, index) => {
    
      tl.push(gsap.timeline({
    
        scrollTrigger:{
          trigger: row,
          start: "top 100%",
          end: "bottom",
          // markers: true,
          toggleActions: 'play none none reverse'
        }
    
      }))
      
    
      const elements = row.querySelectorAll('.stagger-up');
    
      console.log(elements)
    
      elements.forEach((element, i) => {
    
        tl[index].from(element, {
    
            opacity: 0,
            y: 100,
            duration: .5,
            ease: "power3.out",
            stagger: 0.1
    
          });
    
      });
    
    });


    const up = Array.from(document.getElementsByClassName("up"));
    
    up.forEach((item, index) => {
      gsap.set(item, { opacity: 0, y: index % 1 ? -100 : 100 });
    
      gsap.to(item, {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: item,
          start: "bottom 90%",
          end: "top",
          // toggleActions: "${onEnter} ${onLeave} ${onEnterBack} ${onLeaveBack}"
          // options: play, pause, resume, reset, restart, complete, reverse, and none
          toggleActions: "play none none reverse"
          // once: true,
          // markers: true
        }
      });
    });

    const down = Array.from(document.getElementsByClassName("down"));
    
    down.forEach((item, index) => {
      gsap.set(item, { opacity: 0, y: index % 1 ? 100 : -100 });
    
      gsap.to(item, {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: item,
          start: "bottom 90%",
          end: "top",
          // toggleActions: "${onEnter} ${onLeave} ${onEnterBack} ${onLeaveBack}"
          // options: play, pause, resume, reset, restart, complete, reverse, and none
          toggleActions: "play none none reverse"
          // once: true,
          // markers: true
        }
      });
    });



    
    
  }, []);
}
export {FadeIn};