import React, { useState } from "react";
import { database } from "./api/firebase";
import ReCAPTCHA from "react-google-recaptcha";
import CookieConsent from "react-cookie-consent";
import { ref, push, child, update } from "firebase/database";
import { Helmet } from "react-helmet";

function Form(props) {
  function onChange(value) {
    console.log("Captcha value:", value);
  }

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [company, setcompany] = useState(null);
  const [message, setMessage] = useState(null);
  const [alert, setAlert] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "firstName") {
      setFirstName(value);
    }
    if (id === "lastName") {
      setLastName(value);
    }
    if (id === "email") {
      setEmail(value);
    }
    if (id === "company") {
      setcompany(value);
    }
    if (id === "message") {
      setMessage(value);
    }
    if (id === "Alert") {
      setAlert(value);
    }
  };

  const handleSubmit = async () => {
    if (!firstName || !lastName || !email || !company) {
      setAlert("All contact fields are required");
      return;
    } else if (!email.includes("@")) {
      setAlert("Invalid email address");
      return;
    }
    window.lintrk("track", { conversion_id: 10656700 });
    console.log("Form Submitted!");
    let obj = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      company: company,
      message: message,
    };
    const newPostKey = push(child(ref(database), "posts")).key;
    const updates = {};
    updates["/" + newPostKey] = obj;
    await update(ref(database), updates);
    setFormSubmitted(true);
    setAlert("Form submitted successfully!");
  };

  const {
    siteTitle = "Contact us | Book Your Free Hack | NowComms",
    metaDescription = "Book in your free cyber security marketing PowerHack here, just by telling us your current biggest marketing challenge.",
  } = props;

  return (
    <div className="form bg-dark stand-out">
      <Helmet>
        <title>{siteTitle}</title>
        <meta
          name="keywords"
          content="marketing,agency,b2b,security,desing,seo,content"
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={metaDescription}
        />
        <meta property="og:locale" key="og:locale" content="en_GB" />
        <meta name="description" content={metaDescription} />
        <meta property="og:title" key="og:title" content={siteTitle} />
        <meta
          property="og:image"
          key="og:image"
          content="https://nowcomms.com/wp-content/uploads/2021/11/thing.png"
        />
      </Helmet>
      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <div className="container">
        <form id="secure-form">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="form">
                <div className="form-body">
                  <div className="username">
                    <input
                      className="form__input"
                      type="text"
                      value={firstName}
                      onChange={(e) => handleInputChange(e)}
                      id="firstName"
                      placeholder="First Name"
                      required
                    />
                  </div>
                  <div className="lastname">
                    <input
                      type="text"
                      name=""
                      id="lastName"
                      value={lastName}
                      className="form__input"
                      onChange={(e) => handleInputChange(e)}
                      placeholder="Last Name"
                      required
                    />
                  </div>
                  <div className="email">
                    <input
                      type="email"
                      id="email"
                      className="form__input"
                      value={email}
                      onChange={(e) => handleInputChange(e)}
                      placeholder="Work email"
                      required
                    />
                  </div>
                  <div className="company">
                    <input
                      type="company"
                      id="company"
                      className="form__input"
                      value={company}
                      onChange={(e) => handleInputChange(e)}
                      placeholder="Company"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="message">
                <textarea
                  className="form__input"
                  type="message"
                  id="message"
                  value={message}
                  onChange={(e) => handleInputChange(e)}
                  placeholder="Tell us your top marketing priorities?"
                />
              </div>
            </div>
            <div className="footer">
              <input
                className="alert-msg"
                type="text"
                value={alert}
                onChange={(e) => handleInputChange(e)}
                id="alert"
                placeholder=""
                required
              />
              <div className={`center-check ${formSubmitted ? "hide" : ""}`}>
                <ReCAPTCHA
                  sitekey="6LdAPxIkAAAAADuNwl8plVAGFgO2TK4_PW_iwemy"
                  onChange={onChange}
                />
              </div>
              <button
                onClick={() => handleSubmit()}
                type="submit"
                id="button-submit"
                className={`btn btn-form ${formSubmitted ? "hide" : ""}`}
              >
                Submit
              </button>

              <script
                src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
                async
                defer
              ></script>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Form;
