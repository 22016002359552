import React from "react";
import createRender from "react-dom/client";

import "./index.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  About,
  Offer,
  Blog,
  Posts,
  Post,
  PostTwo,
  Form,
  Thanks,
} from "./components";

const container = document.getElementById("root");

// Create a root.
const root = createRender.createRoot(container);

root.render(
  <Router>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/offer" element={<Offer />} />
      <Route path="/blog" element={<Blog />}>
        <Route path="" element={<Posts />} />
        <Route path="/blog/how-to-market-cyber-security" element={<Post />} />
        <Route
          path="/blog/cyber-security-target-audience/"
          element={<PostTwo />}
        />
      </Route>
      <Route path="/form" element={<Form />}></Route>
      <Route path="/thank-you" element={<Thanks />}></Route>
    </Routes>
    <Footer />
  </Router>
);
