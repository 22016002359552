import { FadeIn } from "./animations/Fade";
import { Logos } from "./animations/logos";
import Lottie from "lottie-react";
import cube from "./lottie/greencube.json";
import line from "./lottie/line.json";
import stack from "./lottie/stack.json";
import triangle from "./lottie/triangle.json";
import idemia from "./img/IDEMIA_logo.svg.png";
import okta from "./img/Okta-Symbol.png";
import trust from "./img/trust-hub.png";
import CookieConsent from "react-cookie-consent";
import React from "react";
import { Helmet } from "react-helmet";
function Home(props) {
  FadeIn();
  Logos();

  const {
    siteTitle = "Home | Cyber Security Marketing Agency | NowComms",
    metaDescription = "NowComms helps cyber security providers become industry leaders, with agile marketing that evolves at the rate of your cyber security solutions.",
  } = props;
  return (
    <div className="about">
      <Helmet>
        <title>{siteTitle}</title>
        <meta
          name="keywords"
          content="marketing,agency,b2b,security,desing,seo,content"
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={metaDescription}
        />
        <meta property="og:locale" key="og:locale" content="en_GB" />
        <meta name="description" content={metaDescription} />
        <meta property="og:title" key="og:title" content={siteTitle} />
        <meta
          property="og:image"
          key="og:image"
          content="https://nowcomms.com/wp-content/uploads/2021/11/thing.png"
        />
      </Helmet>
      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>

      <div className="">
        <div className="container-fluid">
          <div className="row align-items-center ">
            <div className="col-lg-5 mob-hide">
              <div className="hoverEffects">
                <Lottie speed={0.25} animationData={cube} autoPlay={false} />
              </div>
            </div>
            <div className="col-lg-7 hero-content">
              <h1>
                Cyber Security Marketing that keeps pace with your evolving
                brand
              </h1>
              <h2>
                NowComms helps online security providers take control of their
                own story and become industry leaders, with agile marketing that
                evolves at the rate of your cyber security solutions.
              </h2>
              <hr></hr>
              <h3>Ready to talk?</h3>

              <div className="flex-btns">
                <a className="btn-hero" href="/offer">
                  Book a free #hack now!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-stretch ">
          <div className="col-lg-12 section-title fade">
            <div className="tag-line">We've helped companies like:</div>
          </div>
        </div>
        <div className="row align-items-stretch trigger-stagger-up">
          <div className="col-lg-4 stagger-up ">
            <div
              className="list__item-nopad"
              style={{
                backgroundImage: `url(${okta})`,
                backgroundRepeat: "repeat",
                backgroundSize: "100px",
              }}
            >
              <div className="overlay">
                <h2>okta</h2>
                <p>
                  Develop, deploy, measure and manage the international
                  marketing optimisation strategies that help Okta connect and
                  nurture meaningful relationships with new customers in diverse
                  global markets.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 stagger-up">
            <div
              className="list__item-nopad"
              style={{
                backgroundImage: `url(${idemia})`,
                backgroundRepeat: "repeat",
                backgroundSize: "100px",
              }}
            >
              <div className="overlay">
                <h2>IDEMIA</h2>
                <p>
                  Create strategies and brands that communicate IDEMIA’s
                  world-class experience in biometrics, cryptography, data
                  analytics, systems and smart devices to governments and
                  enterprise.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 stagger-up">
            <div
              className="list__item-nopad"
              style={{
                backgroundImage: `url(${trust})`,
                backgroundRepeat: "repeat",
                backgroundSize: "150px",
              }}
            >
              <div className="overlay">
                <h2>trust-hub</h2>
                <p>
                  Develop and launch an agile SAAS brand equipped to communicate
                  the challenges and opportunities data privacy initiatives like
                  GDPR or CCPA create for large enterprises operating globally
                  today.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="logo-pad">
        <div className="logo-wrapper">
          <div className="logo-boxes">
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2019/11/trust-hub-logo.png"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2019/11/Idemia_Logo.png"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://clue.co.uk/wp-content/uploads/2020/05/okta-logo-300x101.png"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2019/11/ateon.png"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://care.hw.ac.uk/img/logos/ieee.jpg"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2019/11/beond.png"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2021/11/Sunamp_pos_pmscg8.png"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2020/05/8WO_TlWN-e1623316786831.jpg"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2020/05/2019_EIL_ELYC_TWOLINE_brown_500px.png"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2020/05/0.png"
              />
            </div>
            <div className="logo-box">
              {" "}
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2021/09/download.png"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2021/11/D4t4.jpg"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2019/11/skilla.png"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2021/11/london-borough-of-hounslow-logo2.png"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2020/05/green-and-partners.png"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2020/05/mind-channel-logo.png"
              />
            </div>
            <div className="logo-box">
              <img
                alt="customer-logo"
                src="https://nowcomms.com/wp-content/uploads/2021/11/Eliga_Logo_Black_noPadding.96a282fce8d7.png"
              />
            </div>
          </div>
        </div>
      </div>
      <section className="padding">
        <div className="container">
          <div className="row flex-row-reverse align-items-center">
            <div className="col-lg-6 fade">
              <div className="row align-items-stretch ">
                <div className="col-lg-12 section-title fade">
                  <div className="tag-line">Engagement experts</div>
                </div>
              </div>
              <h2>
                Your audience doesn’t know how important online security is…
                yet.
              </h2>
              <p>
                Advances in cyber security are rapid and unstoppable: new
                threats call for new defences practically every day.
                <strong>
                  You know that full well, and so do we. But do your clients?
                </strong>
              </p>
              <p>
                At NowComms, we’ll work to convince your audience to take action
                before it’s too late. Become the go-to, trusted figures in the
                security space, and drive lead generations through brand
                building and content marketing.{" "}
              </p>
              <div className="trigger-stagger-up">
                <div className="bg-dark stand-out stagger-up">
                  <h3>
                    Capture your audience’s interest with content designed for
                    them.
                  </h3>
                  <p>
                    We can help you to start conversations with prospects at
                    different knowledge levels and across different media,
                    nurturing them from MQLs to SQLs.
                  </p>

                  <div className="flex-btns">
                    <a
                      className="btn-dark"
                      href="/blog/how-to-market-cyber-security"
                    >
                      Blog: How to market cyber security
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 right mob-hide">
              <div className="stripe-circle">
                <Lottie animationData={stack} speed={0.5} />
              </div>
            </div>
          </div>
          <div className="row align-items-center ">
            <div className="col-lg-6 fade">
              <div className="row align-items-stretch ">
                <div className="col-lg-12 section-title fade">
                  <div className="tag-line">Pipeline pioneers</div>
                </div>
              </div>
              <h2>Engage your prospects to secure sales down the line</h2>
              <p>
                Plenty of B2B marketing misses the mark by miles: it assumes the
                customer knows the intricacies of cyber security like the back
                of their hand.{" "}
                <strong>
                  Talk to your prospects in the way they want to be spoken to,
                  and answer the questions they need answered
                </strong>{" "}
                before coming to a purchase decision.
              </p>
              <p>
                You’ll need different content for differing personas: CISOs and
                end users will each have their own particular pain points.
                Identify the different audiences you’re talking to and give them
                each the tailored information they desire.
              </p>
              <div className="trigger-stagger-up">
                <div className="bg-dark stand-out stagger-up">
                  <h3>
                    When they understand why your services are crucial in
                    today’s digital environment, they’ll want to buy.
                  </h3>
                  <div className="flex-btns">
                    <a className="btn-dark" href="/offer">
                      Book a free #hack now!
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 left  mob-hide">
              <div className="stripe-circle">
                <Lottie animationData={line} autoPlay={false} />
              </div>
            </div>
          </div>
          <div className="row flex-row-reverse align-items-center ">
            <div className="col-lg-6 fade">
              <div className="row align-items-stretch ">
                <div className="col-lg-12 section-title fade">
                  <div className="tag-line">
                    <span>Industry insiders</span>
                  </div>
                </div>
              </div>
              <h2>Put yourself front and centre of the cyber security world</h2>
              <p>
                It’s time to establish yourselves as leaders in cyber security.
                Just as your own products and services will evolve to combat the
                latest digital threats, your messaging must change to suit the
                current environment.
              </p>
              <p>
                Place your business at the forefront of the industry with
                content perfectly suited to the growing developments and trends
                of the moment. Cement your place at the top with evergreen
                content that will become your customers’ most trusted space for
                information.
              </p>
              <div className="trigger-stagger-up">
                <div className="bg-dark stand-out stagger-up">
                  <h3>
                    NowComms has the industry knowledge to talk about what you
                    do, to the people you want to reach.
                  </h3>
                  <h4>Ready to talk?</h4>

                  <div className="flex-btns">
                    <a
                      className="btn-dark"
                      href="/blog/cyber-security-target-audience/"
                    >
                      Blog: Cyber security's target audiences
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 right  mob-hide">
              <div className="stripe-circle">
                <Lottie animationData={triangle} autoPlay={false} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="contact bg-dark padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 call-to">
              <h2>Simply start by saying hi</h2>{" "}
            </div>
            <div className="col-lg-6">
              <div className="button-container">
                <a className="btn-form" href="/offer">
                  {" "}
                  View offer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
